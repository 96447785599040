<script setup>
import {nextTick, ref} from 'vue';
import Dropdown from '@/Components/Dropdown.vue';
import DropdownLink from '@/Components/DropdownLink.vue';
import NavLink from '@/Components/NavLink.vue';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';
import { Link } from '@inertiajs/vue3';
import {
    SparklesIcon,
    Bars3Icon,
    XMarkIcon,
    HomeIcon,
    UserCircleIcon,
    GlobeEuropeAfricaIcon,
    GlobeAltIcon,
    UserGroupIcon,
    DocumentTextIcon,
    ChatBubbleBottomCenterIcon,
    ArrowUpOnSquareIcon,
    UserIcon,
} from "@heroicons/vue/24/solid"
import {usePage} from "@inertiajs/vue3";

const page = usePage();

const showingNavigationDropdown = ref(false);
const user = page?.props?.auth?.user;
const notifications = page?.props?.auth?.notifications;
const username = user?.username

console.log(username)
console.log(notifications)

const unreadCount = ref(notifications?.length); // or derive from notifications

const toggleMenu = async () => {
    console.log("Menu clicked"); // Debugging log
    showingNavigationDropdown.value = !showingNavigationDropdown.value;
    // Force Vue to update the DOM after toggling
    await nextTick();
    console.log("Menu state updated:", showingNavigationDropdown.value);
};

</script>

<template>
    <div>
        <div class="min-h-screen bg-darker">
            <nav class="
            fixed
            inset-x-0
            z-20
            w-full
            border-b
            backdrop-blur
            border-gray-700/30
            bg-black/80">
                <div class="mx-auto px-4">
                    <div class="flex h-16 justify-between">
                        <div class="flex">
                            <div class="flex shrink-0 items-center">
                                <Link href="/">
                                    <img
                                        src="../../../resources/images/logo-white.png"
                                        alt="Logo"
                                        class="h-auto mb-2 navigation-logo"
                                    />
                                </Link>
                            </div>

                            <!-- Navigation Links -->
                            <div
                                class="hidden space-x-8 sm:-my-px sm:ms-10 sm:flex"
                            >
                                <NavLink
                                    :href="route('dashboard')"
                                    :active="route().current('dashboard')"
                                    class="flex items-center gap-2"
                                >
                                    <HomeIcon class="h-5 w-5 mr-2" />
                                    <span>Dashboard</span>
                                </NavLink>
                                <NavLink
                                    v-if="$page?.props?.auth?.user"
                                    :href="'/u/' + username"
                                    :active="route().current('profile')"
                                    class="flex items-center gap-2"
                                >
                                    <UserCircleIcon class="h-5 w-5 mr-2" />
                                    <span>Profile</span>
                                </NavLink>
                                <NavLink
                                    href="/explore"
                                    :active="route().current('explore')"
                                    class="flex items-center gap-2"
                                >
                                    <GlobeEuropeAfricaIcon class="h-5 w-5 mr-2" />
                                    <span>Explore</span>
                                </NavLink>
<!--                                <NavLink-->
<!--                                    href="/wiki"-->
<!--                                    :active="route().current('wiki')"-->
<!--                                    class="flex items-center gap-2"-->
<!--                                >-->
<!--                                    <GlobeAltIcon class="h-5 w-5 mr-2" />-->
<!--                                    <span>Wiki</span>-->
<!--                                </NavLink>-->
                                <NavLink
                                    href="/communities"
                                    :active="route().current('communities')"
                                    class="flex items-center gap-2"
                                >
                                    <UserGroupIcon class="h-5 w-5 mr-2" />
                                    <span>Communities</span>
                                </NavLink>
                                <NavLink
                                    href="/articles"
                                    :active="route().current('articles')"
                                    class="flex items-center gap-2"
                                >
                                    <DocumentTextIcon class="h-5 w-5 mr-2" />
                                    <span>Articles</span>
                                </NavLink>
                                <NavLink
                                    href="/feed"
                                    :active="route().current('feed')"
                                    class="flex items-center gap-2"
                                >
                                    <ChatBubbleBottomCenterIcon class="h-5 w-5 mr-2" />
                                    <span>Feed</span>
                                </NavLink>
<!--                                <NavLink-->
<!--                                    v-if="user"-->
<!--                                    href="/ai"-->
<!--                                    :active="route().current('ai')"-->
<!--                                    class="flex items-center gap-2"-->
<!--                                >-->
<!--                                    <SparklesIcon class="h-6 w-6 mr-3 flex-shrink-0"></SparklesIcon>-->
<!--                                </NavLink>-->
                            </div>
                        </div>

                        <div class="hidden sm:ms-6 sm:flex sm:items-center">
                            <!-- Add this block where you have the "Upload" button or alongside it, for instance. -->
                            <div class="relative ms-3 mr-3" v-if="user">
                                <Dropdown align="right" width="48">
                                    <!-- Trigger slot: the icon/button that toggles the dropdown -->
                                    <template #trigger>
                                        <span class="inline-flex rounded-md relative">
                                            <!-- Button with bell icon -->
                                            <button
                                                type="button"
                                                class="inline-flex items-center px-3 py-2 rounded-md border border-gray-900 bg-transparent text-gray-300 hover:text-white focus:outline-none"
                                            >
                                                <!-- Heroicons bell, for example -->
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="h-5 w-5"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M15 17h5l-1.405-1.405C18.403 14.79 18 13.918 18 13V9a6 6 0 00-12 0v4c0 .918-.403 1.79-1.595 2.595L3 17h5m2 0a3 3 0 006 0"
                                                    />
                                                </svg>

                                                <!-- Optional badge if you have unread notifications -->
                                                <span
                                                    v-if="unreadCount > 0"
                                                    class="absolute top-0 right-0 inline-flex items-center justify-center rounded-full bg-red-600 text-white text-xs h-4 w-4 transform translate-x-1/2 -translate-y-1/2"
                                                >
                                                    {{ unreadCount }}
                                                </span>
                                            </button>
                                        </span>
                                    </template>

                                    <!-- Dropdown content slot: the list of notifications -->
                                    <template #content>
                                        <div class="">
                                            <!-- If you have an array of notifications -->
                                            <div v-if="notifications.length === 0" class="p-3 text-sm text-gray-500">
                                                No new notifications
                                            </div>
                                            <div v-else>
                                                <div
                                                    v-for="notification in notifications"
                                                    :key="notification.id"
                                                    class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition"
                                                >
                                                    {{ notifications?.data?.message }}
                                                    {{ notification?.data?.content }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>

                            <!-- Upload Button -->
                            <a
                                href="/upload"
                                class="inline-flex items-center rounded-md border border-gray-900 bg-transparent px-3 py-2 text-sm font-medium leading-4 text-gray-300 transition duration-150 ease-in-out hover:text-white hover:bg-gray-700 focus:outline-none"
                            >
                                Upload
                            </a>

                            <!-- Settings Dropdown -->
                            <div class="relative ms-3" v-if="user">
                                <Dropdown align="right" width="48">
                                    <template #trigger>
                                        <span class="inline-flex rounded-md">
                                            <button
                                                type="button"
                                                class="inline-flex items-center rounded-md border border-gray-900 bg-transparent px-3 py-2 text-sm font-medium leading-4 text-gray-300 transition duration-150 ease-in-out hover:text-white focus:outline-none"
                                            >
                                                {{ user?.name }}
                                                <svg
                                                    class="-me-0.5 ms-2 h-4 w-4 text-gray-300"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                            </button>
                                        </span>
                                    </template>

                                    <template #content>
                                        <div class="bg-white rounded-md shadow-lg py-2">
                                            <DropdownLink
                                                :href="'/u/' + username"
                                                class="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white transition duration-150 ease-in-out"
                                            >
                                                Profile
                                            </DropdownLink>
                                            <DropdownLink
                                                href="'/settings"
                                                class="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white transition duration-150 ease-in-out"
                                            >
                                                Settings
                                            </DropdownLink>
                                            <DropdownLink
                                                :href="route('logout')"
                                                method="post"
                                                as="button"
                                                class="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white transition duration-150 ease-in-out"
                                            >
                                                Log Out
                                            </DropdownLink>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>

                        <!-- Hamburger -->
                        <div class="-me-2 flex items-center sm:hidden">
                            <button
                                v-if="!showingNavigationDropdown"
                                @click="toggleMenu"
                                class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 transition duration-150 ease-in-out hover:bg-black hover:text-gray-500 focus:bg-darker focus:text-gray-500 focus:outline-none"
                            >
                                <Bars3Icon class="w-5 h-5"  />
                            </button>
                            <button
                                v-else
                                @click="toggleMenu"
                                class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 transition duration-150 ease-in-out hover:bg-black hover:text-gray-500 focus:bg-darker focus:text-gray-500 focus:outline-none"
                            >

                                <XMarkIcon class="w-5 h-5" />
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Responsive Navigation Menu -->
                <div
                    v-show="showingNavigationDropdown"
                    class="z-50"
                >
                    <div class="space-y-1 pb-3 pt-2">
                        <ResponsiveNavLink
                            :href="route('dashboard')"
                            :active="route().current('dashboard')"
                            class="flex items-center gap-2"
                        >
                            <HomeIcon class="h-5 w-5" />
                            Dashboard
                        </ResponsiveNavLink>
                        <ResponsiveNavLink
                            v-if="$page?.props?.auth?.user"
                            :href="'/u/' + username"
                            :active="route().current('profile')"
                            class="flex items-center gap-2"
                        >
                            <UserCircleIcon class="h-5 w-5" />
                            Profile
                        </ResponsiveNavLink>
                        <ResponsiveNavLink
                            v-if="$page?.props?.auth?.user"
                            href="/upload"
                            :active="route().current('upload')"
                            class="flex items-center gap-2"
                        >
                            <ArrowUpOnSquareIcon class="h-5 w-5" />
                            Upload
                        </ResponsiveNavLink>
                        <ResponsiveNavLink
                            :href="route('explore')"
                            :active="route().current('explore')"
                            class="flex items-center gap-2"
                        >
                            <GlobeEuropeAfricaIcon class="h-5 w-5" />
                            Explore
                        </ResponsiveNavLink>
<!--                        <ResponsiveNavLink-->
<!--                            :href="route('wiki')"-->
<!--                            :active="route().current('wiki')"-->
<!--                            class="flex items-center gap-2"-->
<!--                        >-->
<!--                            <GlobeAltIcon class="h-5 w-5" />-->
<!--                            Wiki-->
<!--                        </ResponsiveNavLink>-->
                        <ResponsiveNavLink
                            :href="route('communities')"
                            :active="route().current('communities')"
                            class="flex items-center gap-2"
                        >
                            <UserGroupIcon class="h-5 w-5" />
                            Communities
                        </ResponsiveNavLink>
                        <ResponsiveNavLink
                            :href="route('articles')"
                            :active="route().current('articles')"
                            class="flex items-center gap-2"
                        >
                            <DocumentTextIcon class="h-5 w-5" />
                            Articles
                        </ResponsiveNavLink>
                        <ResponsiveNavLink
                            :href="route('feed')"
                            :active="route().current('feed')"
                            class="flex items-center gap-2"
                        >
                            <ChatBubbleBottomCenterIcon class="h-5 w-5" />
                            Feed
                        </ResponsiveNavLink>
<!--                        <ResponsiveNavLink-->
<!--                            :href="route('ai')"-->
<!--                            :active="route().current('ai')"-->
<!--                            class="flex items-center gap-2"-->
<!--                        >-->
<!--                            <SparklesIcon class="h-6 w-6 mr-3 flex-shrink-0"></SparklesIcon>-->
<!--                        </ResponsiveNavLink>-->
                    </div>

                    <!-- Responsive Settings Options -->
                    <div
                        class="border-t border-black pb-1 pt-4"
                        v-if="$page?.props?.auth?.user"
                    >
                        <div class="px-4">
                            <div
                                class="text-base text-white font-bold"
                            >
                                {{ user?.name }}
                            </div>
                            <div class="text-sm font-medium text-gray-50">
                                {{ user?.email }}
                            </div>
                        </div>

                        <div class="mt-3 space-y-1">
                            <ResponsiveNavLink :href="'/u/' + username" class="text-white">
                                Profile
                            </ResponsiveNavLink>
                            <ResponsiveNavLink
                                :href="route('logout')"
                                method="post"
                                as="button"
                                class="text-white"
                            >
                                Log Out
                            </ResponsiveNavLink>
                        </div>
                    </div>
                </div>
            </nav>

            <!-- Page Heading -->
            <header
                class="bg-white shadow"
                v-if="$slots.header"
            >
                <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                    <slot name="header" />
                </div>
            </header>

            <!-- Page Content -->
            <main class="pt-16">
                <slot />
            </main>
        </div>
    </div>
</template>

<style>
.navigation-logo {
    height: 20px;
}
</style>
